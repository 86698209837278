<template>
  <div class="inner-acc">
    <div class="wrapper rowflex">
      <sidebar-account></sidebar-account>
      <article class="main">
        <div class="detail-trx">
          <a href="#" class="cvr-bg-bf back-link">Transacation Details</a>
          <div class="rowflex">
            <div class="info">
              <div class="item booking">
                <div class="bhead">
                  <table>
                    <tr>
                      <td>
                        <small>Status</small>
                        <span style="background:#F4BE39;"
                          >Waiting Approval</span
                        >
                      </td>
                      <td>
                        <small>Booking Date</small>
                        <strong>18 Nov 2021</strong>
                      </td>
                    </tr>
                  </table>
                </div>
                <!-- end of behead -->
                <h2>Booking Details</h2>
                <div class="prop">
                  <figure>
                    <a href="#">
                      <img src="/img/dummy/acc/1.jpg" width="76" />
                    </a>
                  </figure>
                  <div class="caption">
                    <h3><a href="#">Cluster Eureka at Nevada</a></h3>
                    <div class="location cvr-bg-bf">
                      <span>Padalarang, Bandung</span>
                      <span>Furnished</span>
                    </div>
                  </div>
                </div>
                <!-- end of prop -->
                <table>
                  <tr>
                    <td>
                      <small>Transaction Type</small>
                      <strong>Rent</strong>
                    </td>
                    <td>
                      <small>Rent Period</small>
                      <strong>1 Year</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small>Move In</small>
                      <strong>25 Nov 2021</strong>
                    </td>
                    <td>
                      <small>Move Out</small>
                      <strong>23 Sep 2022</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small>Payment Option</small>
                      <strong>Monthly</strong>
                    </td>
                    <td>
                      <small>Effective Rent Per Month</small>
                      <b>Rp 3.480.000</b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <small>Total Rent</small>
                      <b>Rp 41.760.000</b>
                    </td>
                  </tr>
                </table>
              </div>
              <!-- end of item booking -->
              <div class="item payment">
                <h2>Payment Schedule</h2>
                <table>
                  <tbody>
                    <tr class="current">
                      <td>Booking Fee</td>
                      <td>18 Nov 2021</td>
                      <td>Rp 5.000.000</td>
                      <td>Waiting Confirmation</td>
                    </tr>
                    <tr>
                      <td>
                        Rental Bond
                        <span class="cvr-bg qmark"
                          ><div class="layer">
                            <strong>Next payment</strong>
                            <b>Senin, 20 Apr 2021, 20:00 WIB</b>
                          </div></span
                        >
                      </td>
                      <td>23 Nov 2021</td>
                      <td>Rp 8.700.000</td>
                      <td>Future Payment</td>
                    </tr>
                    <tr>
                      <td>
                        Payment 1
                      </td>
                      <td>23 Nov 2021</td>
                      <td>Rp 3.480.000</td>
                      <td>Future Payment</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2">Total Rent Amount</td>
                      <td colspan="2">Rp 41.760.000</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <!-- end of item -->
            </div>
            <!-- end of inf0 -->
            <div class="action">
              <div class="item paid">
                <table>
                  <tr>
                    <td>
                      <small>You Already Paid</small>
                      <strong>Rp 5.000.000</strong>
                    </td>
                    <td>
                      <small>Remaining Payment</small>
                      <strong>Rp 36.760.000</strong>
                    </td>
                  </tr>
                </table>
              </div>
              <!-- end of paid -->
              <em>We are processing your booking, please wait as we confirm</em>
            </div>
            <!-- end of action -->
          </div>
          <!-- end of rowflex -->
        </div>
        <!-- end of detail trx -->
      </article>
    </div>
    <!-- end of wrapper -->
  </div>
  <!-- end of inner mort -->
</template>

<script>
import SidebarAccount from "./SidebarAccount.vue";
// import * as moment from "moment";
// import cookie from "js-cookie";
// import $ from "jquery";

export default {
  name: "AccountTransaction",
  metaInfo: {
    title: "Account Transaction"
  },
  components: {
    SidebarAccount
  },
  data() {
    return {
      transactions: []
    };
  },
  mounted() {},
  methods: {}
};
</script>
